import React from 'react';

import Field from '../common/forms/Field/Field';
import { Area } from '../../graphqlTypes';

interface ListNewLetterAreasProps {
  listAreas: Area[];
  areasUserActive: number[];
  chooseArea: (idArea: number) => void;
}

const ListNewLetterAreas = ({
  listAreas,
  areasUserActive,
  chooseArea,
}: ListNewLetterAreasProps): JSX.Element => {
  if (!listAreas) return null;

  return (
    <>
      {listAreas.map((area) => {
        const checked = areasUserActive.indexOf(parseInt(area.id as unknown as string, 10)) > -1;

        return (
          <Field
            propsInput={{
              onClick: (): void => chooseArea(area.id),
              checked,
            }}
            id={area.id as unknown as string}
            littleCheck
            key={area.id}
            label={area.nameEs}
            type="checkbox"
          />
        );
      })}
    </>
  );
};

export default ListNewLetterAreas;
