/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import ListNewLetterAreas from './ListNewsLetterAreas';
import { subscribeToNewsletterMutation, getUserAreas } from './services/newsletter.service';
import Form from '../common/forms/Form';
import Field from '../common/forms/Field/Field';
import Button from '../common/forms/Button';
import Link from '../common/link/Link';
import { Area } from '../../graphqlTypes';
import userReactiveVar, { useReactiveVariableUser } from '../../hooks/useReactiveVariableUser';
import { errorNotification, asyncNotification } from '../../utils/services/toast.service';

interface ChooseAreasProps {
  listAreas?: Area[];
  account?: boolean;
  mail?: string;
  onChange?: (data: number[]) => void;
}

interface ChooseAreasState {
  active: boolean;
  fadeOut: boolean;
  areasActive: number[];
  setAreas: boolean;
  areas?: Area[];
  openTerms?: boolean;
}

const ChooseAreas = ({ listAreas, account, mail, onChange }: ChooseAreasProps): JSX.Element => {
  const user = useReactiveVar(userReactiveVar);
  const { refetchUser } = useReactiveVariableUser();
  const [state, setState] = useState<ChooseAreasState>({
    active: false,
    fadeOut: false,
    areasActive: user?.profile?.newsletter?.areaOfInterest
      ? [...user.profile?.newsletter?.areaOfInterest]
      : [],
    setAreas: !!listAreas,
    areas: listAreas,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      areasActive: user?.profile?.newsletter?.areaOfInterest
        ? [...user.profile?.newsletter?.areaOfInterest]
        : [],
    }));
  }, [user]);

  const getActiveAreas = async (): Promise<void> => {
    const areasMail = user?.email || mail;

    const { ok, data } = await getUserAreas({ email: areasMail });

    if (!ok) return;

    const { areas } = state;
    if (data.status === 'subscribed') {
      setState((prevState) => ({
        ...prevState,
        areas,
        areasActive: data?.areaOfInterest,
        active: true,
        setAreas: !!areas,
      }));

      return;
    }

    setState((prevState) => ({
      ...prevState,
      areas,
      areasActive: [],
      active: false,
      setAreas: !!areas,
    }));
  };

  const onChangeActive = (): void => {
    setState((prevState) => ({
      ...prevState,
      active: !state.active,
      openTerms: !state.openTerms,
    }));
  };

  const chooseArea = (idArea: number): void => {
    const areas = [...state.areasActive];
    const index = areas.indexOf(idArea);
    if (index > -1) {
      areas.splice(index, 1);
    } else {
      areas.push(idArea);
    }
    setState((prevState) => ({ ...prevState, areasActive: areas }));
    if (onChange) onChange(areas);
  };

  const saveAreas = async (e): Promise<void> => {
    if (e) {
      e.preventDefault();
    }

    if (!state.active && state.areasActive?.length) {
      errorNotification({
        message: 'Es necesario suscribirse para recibir nuestras ofertas e información',
      });

      return;
    }

    if (state.areasActive.length <= 0) {
      errorNotification({ message: 'Debes de seleccionar alguna área de interés' });

      return;
    }

    const subscribeToNewsletterPromise = subscribeToNewsletterMutation({
      _id: user?._id,
      email: user?.email,
      areaOfInterest: state.areasActive,
      active: state.active,
    });

    await asyncNotification({
      loadingMessage: 'Guardando areas de interés',
      successMessage: 'Se ha editado su perfil correctamente',
      functionAwaitResult: subscribeToNewsletterPromise,
    });

    refetchUser();
  };

  const cancel = (e): void => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!state.setAreas) {
      getActiveAreas();
    }
  }, [listAreas]);

  useEffect(() => {
    if (mail) {
      getActiveAreas();
    }
  }, [mail]);

  return (
    <>
      <Form onSubmit={saveAreas}>
        {account ? (
          <>
            <div className="noNL noMargin">
              <Field
                id="subscription"
                littleCheck
                propsInput={{
                  onClick: onChangeActive,
                  checked: !!state.active,
                }}
                label="Suscríbete para recibir cupones de descuento, ofertas e información de novedades."
                customText={
                  <label className="policy">
                    {`Al suscribirte aceptas expresamente la `}
                    <Link className="pink" href="/privacidad" rel="nofollow" aria="privacidad">
                      Política de Privacidad
                    </Link>
                    {` de Axón`}
                  </label>
                }
                type="checkbox"
              />
            </div>

            <p className="helpUs">Marca todas las áreas que sean de tu interés.</p>
          </>
        ) : null}

        {account ? (
          <div className="actionsHolder justEnd">
            <Button
              className="rounded bordered cta"
              iconRight
              iconClass="close"
              onClick={cancel}
              type="button"
            >
              Cancelar
            </Button>

            <Button className="rounded bordered cta" iconRight iconClass="check" type="submit">
              Guardar
            </Button>
          </div>
        ) : null}

        <div className="areas">
          <ListNewLetterAreas
            chooseArea={chooseArea}
            listAreas={state.areas}
            areasUserActive={state.areasActive}
          />
        </div>

        {account ? (
          <div className="actionsHolder justEnd">
            <Button
              className="rounded bordered cta"
              iconRight
              iconClass="close"
              onClick={cancel}
              type="button"
            >
              Cancelar
            </Button>
            <Button className="rounded bordered cta" type="submit">
              Guardar
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
};

export default ChooseAreas;
