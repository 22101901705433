/* eslint-disable import/prefer-default-export */

import {
  GetUserAreasQuery,
  GetUserAreasQueryVariables,
  NewsletterStatus,
  SubscribeToNewsletterMutation,
  SubscribeToNewsletterMutationVariables,
  User,
} from '../../../graphqlTypes';
import { subscribeToNewsletter } from '../../../graphql/mutations';
import { initializeApollo } from '../../../graphql/apollo';
import { Response } from '../../../models/response.model';
import { getUserAreas as getUserAreasQuery } from '../../../graphql/queries';

export const subscribeToNewsletterMutation = async (
  variables: SubscribeToNewsletterMutationVariables,
): Promise<Response<User>> => {
  try {
    const { mutate } = initializeApollo();

    const {
      data: { subscribeToNewsletter: subscribeToNewsletterData },
    } = await mutate<SubscribeToNewsletterMutation>({
      mutation: subscribeToNewsletter,
      variables,
      fetchPolicy: 'network-only',
      refetchQueries: [
        {
          query: getUserAreasQuery,
          variables: { email: variables?.email },
        },
      ],
    });

    return {
      ok: !!subscribeToNewsletterData,
      data: subscribeToNewsletterData,
    };
  } catch (error: any) {
    return {
      ok: false,
      data: error?.message,
    };
  }
};

export const getUserAreas = async (
  variables: GetUserAreasQueryVariables,
): Promise<Response<NewsletterStatus>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { getUserAreas: getUserAreasData },
    } = await query<GetUserAreasQuery>({
      query: getUserAreasQuery,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!getUserAreasData,
      data: getUserAreasData,
    };
  } catch (error: any) {
    return {
      ok: false,
      data: error?.message,
    };
  }
};
